<template>
  <div class="app-container" style="padding: 8px;">
    <!--工具栏-->
    <div class="head-container">
      <!-- 搜索 -->
      <el-input v-model="query.value" clearable placeholder="输入内容模糊搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <!-- 新增 -->
      <div style="display: inline-block;margin: 0px 2px;">
        <el-button v-permission="['ADMIN','LOCALSTORAGE_ALL','LOCALSTORAGE_CREATE']" class="filter-item" size="mini" type="primary" icon="el-icon-upload" @click="add">文件上传</el-button>
      </div>
      <!-- 多选删除 -->
      <div style="display: inline-block;margin: 0px 2px;">
        <el-button :loading="delAllLoading" :disabled="data.length === 0 || $refs.table.selection.length === 0" class="filter-item" size="mini" type="danger" icon="el-icon-delete" @click="open">删除</el-button>
      </div>
    </div>
    <!--表单组件-->
    <eForm ref="form" :is-add="isAdd" />
    <!--表格渲染-->
    <el-table v-loading="loading" ref="table" :data="data" size="small" style="width: 100%;">
      <el-table-column type="selection" width="55" />
      <el-table-column :show-overflow-tooltip="true" prop="name" label="文件名">
        <template slot-scope="scope">
          <el-link :underline="false" :href="baseApi + '/file/' + scope.row.type + '/' + scope.row.realName" target="_blank" type="primary">{{ scope.row.name }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="suffix" label="文件类型" />
      <el-table-column prop="type" label="类别" />
      <el-table-column prop="size" label="大小" />
      <el-table-column prop="operate" label="操作人" />
      <el-table-column prop="createTime" label="创建日期">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="修改日期">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.updateTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="checkPermission(['ADMIN','LOCALSTORAGE_ALL','LOCALSTORAGE_EDIT','LOCALSTORAGE_DELETE'])" width="150px" align="center">
        <template slot-scope="scope">
          <el-button v-permission="['ADMIN','LOCALSTORAGE_ALL','LOCALSTORAGE_EDIT']" size="mini" type="primary" icon="el-icon-edit" @click="edit(scope.row)" />
          <el-popover v-permission="['ADMIN','LOCALSTORAGE_ALL','LOCALSTORAGE_DELETE']" :ref="scope.row.id" placement="top" width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { del, delAll } from "@/api/localStorage";
import { parseTime } from "@/utils/index";
import eForm from "./form";
export default {
  components: { eForm },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      delAllLoading: false,
      queryTypeOptions: [
        { key: "name", display_name: "文件名" },
        { key: "suffix", display_name: "后缀" },
        { key: "type", display_name: "类型" },
        { key: "operate", display_name: "操作人" }
      ],
      query:{
        value:null,
      }
    };
  },
  computed: {
    ...mapGetters(["baseApi"])
  },
  created() {
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = "api/localStorage";
      const sort = "id,desc";
      this.params = { page: this.page, size: this.size, sort: sort };
      const query = this.query;
      const value = query.value;
      if (value) {
        this.params["blurry"] = value;
      }
      return true;
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then(res => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500
          });
        })
        .catch(err => {
          this.delLoading = false;
          this.$refs[id].doClose();
          console.log(err.response.data.message);
        });
    },
    add() {
      this.isAdd = true;
      this.$refs.form.dialog = true;
    },
    edit(data) {
      this.isAdd = false;
      const _this = this.$refs.form;
      _this.form = {
        id: data.id,
        name: data.name
      };
      _this.dialog = true;
    },
    doDelete() {
      this.delAllLoading = true;
      const data = this.$refs.table.selection;
      const ids = [];
      for (let i = 0; i < data.length; i++) {
        ids.push(data[i].id);
      }
      delAll(ids)
        .then(res => {
          this.delAllLoading = false;
          this.dleChangePage(ids.length);
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500
          });
        })
        .catch(err => {
          this.delAllLoading = false;
          console.log(err.response.data.message);
        });
    },
    open() {
      this.$confirm("你确定删除选中的数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.doDelete();
      });
    }
  }
};
</script>

<style scoped>
</style>
